<template>
  <section class="payment container">
    <div class="payment-box">
      <h2 class="payment__h2 text-center"> {{ $cookie.get('lang') === 'Ru' ? 'Политика конфиденциальности' : 'Құпиялылық саясаты' }}</h2>
      <p>
        {{ $cookie.get('lang') === 'Ru' ? `Настоящая Политика конфиденциальности данных (далее – Политика
        конфиденциальности) действует в отношении данных, которые АО «ForteBank»
        (БИН 980 341 000 286, г. Астана, ул. Достык, 8/1) может получить с
        использованием веб-сайта, расположенного в сети Интернет на доменном
        имени: https://www.tahit.kz. Общество обрабатывает данные пользователей
        Сайта исключительно в рамках требований Законодательства Республики
        Казахстан, в том числе Закона Республики Казахстан от 21 мая 2013 года №
        94-V «О персональных данных и их защите», а также международных
        договоров, ратифицированных Республики Казахстан. Политика
        конфиденциальности (далее — Политика) разработана в соответствии с их
        нормами. Использование Пользователем Сайта означает согласие с Политикой
        конфиденциальности и условиями обработки данных. В случае несогласия с
        условиями Политики конфиденциальности Пользователь должен прекратить
        использование Сайта. Настоящая Политика предназначена для обеспечения
        надлежащей защиты информации о Пользователях, в том числе их
        персональных данных, от несанкционированного доступа и разглашения.
        Политика, а также другая официальная документация Сайта и действующее
        законодательство Республики Казахстан регулируют отношения, связанные со
        сбором, хранением, распространением и защитой информации о пользователях
        Сайта. В случае наличия противоречий между настоящей Политикой и иными
        официальными документами Сайта применению подлежит настоящая Политика.
        Пользователи должны знать, что при переходе на некоторые ссылки,
        размещённые на Сайте, они могут быть перенаправлены к сайтам
        (приложениям и т.д.) других компаний за пределы хостингового
        пространства АО «ForteBank» («Facebook», «google+», «ВКонтакте), где
        информация о Пользователях собирается вне прямого контроля АО
        «ForteBank» и Администрации Сайта. В таком случае Политики
        конфиденциальности сайтов и/или приложений третьих лиц будут
        регулировать порядок обработки информации, полученной от пользователей
        этими третьими лицами. АО «ForteBank» и Администрация Сайта не несут
        ответственности за переданную Пользователем этим третьим лицам
        информацию. Если Пользователь решил войти на сайт, используя службу
        аутентификации стороннего оператора, например: информацию Facebook,
        Общество может получить дополнительный профиль, или другую информацию,
        доступ к которой разрешен Пользователем в аккаунте стороннего оператора
        для предоставления третьим лицам.` : `Осы деректердің құпиялылығы саясаты (бұдан әрі Саясат деп аталады).
         құпиялылық) «ForteBank» АҚ деректеріне қолданылады
         (БСН 980 341 000 286, Астана қ., Достық к-сі, 8/1) мекенжайынан алуға болады.
         домендегі интернетте орналасқан веб-сайтты пайдалану
         атауы: https://www.tahit.kz. Компания пайдаланушы деректерін өңдейді
         Сайттың тек республика заңнамасының талаптары шеңберінде ғана
         Қазақстан, оның ішінде Қазақстан Республикасының 2013 жылғы 21 мамырдағы №
         94-V «Дербес деректер және оларды қорғау туралы», сондай-ақ халықаралық
         Қазақстан Республикасы ратификациялаған шарттар. Саясат
         оларға сәйкес құпиялылық саясаты (бұдан әрі - Саясат) әзірленді
         нормалар. Пайдаланушының Сайтты пайдалануы Саясаттың қабылданғанын білдіреді
         құпиялылық және деректерді өңдеу шарттары. келіспеген жағдайда
         Құпиялық саясатының шарттары Пайдаланушы тоқтауы керек
         Сайтты пайдалану. Бұл Саясат қамтамасыз етуге арналған
         Пайдаланушылар, соның ішінде олардың туралы ақпаратты тиісті түрде қорғау
         рұқсатсыз кіруден және жария етуден жеке деректер.
         Саясат, сондай-ақ Сайттың басқа ресми құжаттамасы және ағымдағы
         байланысты қатынастарды Қазақстан Республикасының заңнамасы реттейді
         пайдаланушылар туралы ақпаратты жинау, сақтау, тарату және қорғау
         Сайт. Осы Саясат пен басқалары арасында қайшылық болған жағдайда
         Сайттың ресми құжаттары, осы Саясат қолданылуға жатады.
         Пайдаланушылар кейбір сілтемелерді басқан кезде,
         Сайтта орналастырылған болса, олар сайттарға қайта бағытталуы мүмкін
         (қосымшалар және т.б.) хостингтен тыс басқа компаниялар
         «ForteBank» АҚ кеңістігі («Facebook», «google+», «ВКонтакте»), онда
         Пайдаланушылар туралы ақпарат АҚ тікелей бақылауынан тыс жиналады
         «ForteBank» және Сайт әкімшілігі. Бұл жағдайда саясат
         үшінші тарап веб-сайттарының және/немесе қолданбаларының құпиялылық тәжірибесі болады
         пайдаланушылардан алынған ақпаратты өңдеуді реттейді
         осы үшінші тараптар. «ForteBank» АҚ және Сайт әкімшілігі жауапкершілік көтермейді
         Осы үшінші тұлғаларға берілген Пайдаланушы үшін жауапкершілік
         ақпарат. Пайдаланушы сайтқа сервисті пайдаланып кіруді шешсе
         үшінші тарап операторының аутентификациясы, мысалы: Facebook ақпараты,
         Қоғам қосымша профильді немесе басқа ақпаратты ала алады,
         қол жеткізуге Пайдаланушы үшінші тарап операторының есептік жазбасында рұқсат берген
         үшінші тұлғаларға беруге.`}}
        
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start"> {{ $cookie.get('lang') === 'Ru' ? 'Определение терминов' : 'Терминдердің анықтамасы' }} </h2>
      <p>
        {{ $cookie.get('lang') === 'Ru' ? `В Политике конфиденциальности используются следующие термины:
        «Администрация сайта» — уполномоченные сотрудники, действующие от имени
        АО «ForteBank», которые организуют и (или) осуществляют Обработку
        конфиденциальных данных, а также определяет цели обработки
        конфиденциальных данных, состав конфиденциальных данных, подлежащих
        обработке, действия (операции), совершаемые с конфиденциальными данными.
        Конфиденциальные данные — данные, оставляемые Пользователем сайта в
        формах обратной связи и заявок на подключение услуг, Cookies, а также
        автоматически передаваемые данные в процессе использования сайта, в том
        числе, но не ограничиваясь: IP-адрес, сведения об устройстве, с которого
        осуществляется доступ и т.д. «Персональные данные» — сведения,
        относящиеся к определенному или определяемому на их основании субъекту
        персональных данных, зафиксированные на электронном, бумажном и (или)
        ином материальном носителе.` : `Құпиялық саясатында келесі терминдер пайдаланылады:
         «Сайт әкімшілігі» – атынан әрекет ететін уәкілетті қызметкерлер
         Процессингті ұйымдастыратын және (немесе) жүзеге асыратын «ForteBank» АҚ
         құпия деректерді, сондай-ақ өңдеу мақсаттарын анықтайды
         құпия деректер, конфиденциалды деректердің құрамы
         өңдеу, құпия деректермен орындалатын әрекеттер (операциялар).
         Құпия деректер - сайттың пайдаланушысы қалдырған деректер
         кері байланыс нысандары және қызметтерді қосуға арналған қосымшалар, Cookie файлдары, сондай-ақ
         сайтты пайдалану кезінде автоматты түрде жіберілетін деректер, соның ішінде
         соның ішінде, бірақ олармен шектелмей: IP мекенжайы, қай құрылғы туралы ақпарат
         қол жеткізу жүзеге асырылады және т.б. «Жеке деректер» ақпаратты білдіреді
         белгілі бір тақырыпқа қатысты немесе олардың негізінде анықталады
         электрондық, қағазда және (немесе) жазылған жеке деректер
         басқа материалды тасымалдаушы.` }}
        
      </p>
      <p>
         {{ $cookie.get('lang') === 'Ru' ? `«Обработка персональных данных» — любое действие (операция) или
        совокупность действий (операций), совершаемых с использованием средств
        автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передачу
        (предоставление, доступ), обезличивание, блокирование, удаление,
        уничтожение персональных данных. «Конфиденциальность персональных
        данных» — обязательное для соблюдения Оператором или иным получившим
        доступ к персональным данным лицом требование не допускать их
        распространения без согласия субъекта персональных данных или наличия
        иного законного основания. «Пользователь» сайта` : `«Жеке деректерді өңдеу» - кез келген әрекет (операция) немесе
         қаражаттарды пайдалана отырып орындалатын әрекеттердің (операциялардың) жиынтығы
         автоматтандыру немесе мұндай құралдарды қолданбай жеке
         мәліметтерді жинақтау, тіркеу, жүйелеу, жинақтау, сақтау,
         нақтылау (жаңарту, өзгерту), алу, пайдалану, беру
         (қамтамасыз ету, қол жеткізу), иесіздандыру, блоктау, жою,
         жеке деректерді жою. «Жеке құпиялылық
         деректер» - Оператор немесе басқа алушы үшін міндетті
         тұлғаның жеке деректерге қол жеткізуі, оларға рұқсат бермеу талабы
         дербес деректер субъектісінің келісімінсіз немесе қатысуынсыз тарату
         басқа да құқықтық негіз. Сайттың «пайдалануы».` }}
        
        <a href="#" class="payment-mail"> https://www.tahit.kz.</a>
        {{ $cookie.get('lang') === 'Ru' ? `— лицо,
        имеющее доступ к Сайту посредством сети Интернет и использующее Сайт.
        «Сайт» — веб-сайт, расположенный в сети Интернет на доменном адресе` : `-бет,
         Интернет арқылы Сайтқа қол жеткізу және Сайтты пайдалану.
         «Сайт» - Интернетте домендік мекенжай бойынша орналасқан веб-сайт` }}
         
        <a href="#" class="payment-mail"> https://www.tahit.kz.</a>
        {{ $cookie.get('lang') === 'Ru' ? `«Cookies» —
        небольшой фрагмент данных, отправленный веб-сервером и хранимый на
        компьютере Пользователя, который веб-клиент или веб-браузер каждый раз
        пересылает веб-серверу в HTTP-запросе при попытке открыть страницу
        соответствующего сайта. «IP-адрес» — уникальный сетевой адрес узла в
        компьютерной сети, построенной по протоколу IP.` : `«Cookie файлдары»
         веб-сервер арқылы жіберілген және сақталады деректердің шағын бөлігі
         Пайдаланушының компьютері, қай веб-клиент немесе веб-шолғыш
         бетті ашу әрекеті кезінде HTTP сұрауында веб-серверге жібереді
         сәйкес сайт. "IP мекенжайы" - бұл түйіннің бірегей желілік мекенжайы
         IP протоколы арқылы құрастырылған компьютерлік желі.` }}
         
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        {{ $cookie.get('lang') === 'Ru' ? 'Предмет политики конфиденциальности' : 'Құпиялық саясатының тақырыбы' }}
        
      </h2>
      <p>
        {{ $cookie.get('lang') === 'Ru' ? `Политика конфиденциальности устанавливает обязательства Администрации
        сайта по неразглашению и обеспечению режима защиты конфиденциальности
        данных, которые Пользователь предоставляет Администрации сайта при
        работе с сайтом, в том числе при подаче заявки на подключение.
        Конфиденциальные данные, разрешенные к Обработке в рамках Политики
        конфиденциальности, предоставляются Пользователем путем заполнения
        регистрационной формы (пример представлен ниже), форм обратной связи и
        включают в себя – Наименование Пользователя (указывается по усмотрению
        Пользователя), телефон, почта (адрес электронной почты) Юридического
        лица. Если в форме заявки Пользователь вместо контактных данных
        юридического лица оставляет свои персональные данные, то Общество
        обеспечивает их защиту в соответствии с требованиями Законодательства
        Республики Казахстан о персональных данных. Кроме регистрационной формы
        на Сайте могут использоваться различные системы веб- аналитики. Сайт
        защищает данные, которые автоматически передаются в процессе просмотра
        рекламных блоков и при посещении страниц Сайта, в том числе:` : `Құпиялық саясаты Әкімшіліктің міндеттерін белгілейді
         жария етпеуге және құпиялылықты қорғауға арналған веб-сайт
         Пайдаланушы Сайт Әкімшілігіне беретін деректер
         сайтпен жұмыс істеу, оның ішінде қосылуға өтініш беру кезінде.
         Саясатқа сәйкес өңдеуге рұқсат етілген құпия деректер
         толтыру арқылы Пайдаланушы қамтамасыз ететін құпиялылық
         тіркеу формасы (мысал төменде келтірілген), кері байланыс нысандары және
         қамтиды – Пайдаланушы аты (өз қалауы бойынша көрсетіледі
         Заңды тұлғаның пайдаланушысы), телефоны, поштасы (электрондық пошта мекенжайы).
         беттер. Өтініш нысанында байланыс деректерінің орнына Пайдаланушы болса
         заңды тұлға өзінің жеке деректерін Қоғамға қалдырады
         Заңнама талаптарына сәйкес олардың қорғалуын қамтамасыз етеді
         Қазақстан Республикасы дербес деректер туралы. Тіркеу формасына қосымша
         Сайт әртүрлі веб-аналитикалық жүйелерді пайдалана алады. Веб-сайт
         шолу кезінде автоматты түрде берілетін деректерді қорғайды
         жарнама блоктары және Сайттың беттеріне кіру кезінде, соның ішінде:` }}

        
        <ul class="payment-list">
        <br />
        <li>
          {{ $cookie.get('lang') === 'Ru' ? ' IP-адрес;' : 'IP мекенжайы;' }}
          <br /></li>
        <li>
          {{ $cookie.get('lang') === 'Ru' ? ' информация из Cookies;' : 'Cookie файлдарынан алынған ақпарат;' }}
          
          <br /></li>
        <li>
          {{ $cookie.get('lang') === 'Ru' ? `информация о браузере (или иной программе, которая осуществляет доступ
        к показу рекламы);` : `шолғыш (немесе басқа бағдарлама) туралы ақпарат
         жарнамаларды көрсету үшін);` }}
          
            <br /></li>
        <li>
          {{ $cookie.get('lang') === 'Ru' ? 'операционная система;' : 'операциялық жүйе;' }}

          <br />
        </li>
        <li> 
          {{ $cookie.get('lang') === 'Ru' ? 'время доступа, часовой пояс/регион;' : 'қол жеткізу уақыты, уақыт белдеуі/аймақ;' }}
          
         <br /></li>
        <li>
          {{ $cookie.get('lang') === 'Ru' ? 'адрес страницы Сайта.' : 'веб-сайт бетінің мекенжайы.' }}
          
           <br /></li>
        </ul>
          {{ $cookie.get('lang') === 'Ru' ? `Отключение Cookies может повлечь невозможность доступа к некоторым
        разделам Cайта. Администрация сайта может осуществлять сбор статистики в
        отношении данных, указанных в п. 2.3. Политики конфиденциальности.
        Данная информация используется с целью выявления и решения технических
        проблем, для противодействия мошенничеству. Информация подлежит
        надежному хранению и нераспространению, за исключением случаев,
        предусмотренных Политикой конфиденциальности и законодательством
        Республики Казахстан.` : `Cookie файлдарын өшіру кейбіріне қол жеткізу мүмкін еместігіне әкелуі мүмкін
         сайттың бөлімдері. Сайт әкімшілігі статистиканы жинай алады
         2.3 тармағында көрсетілген деректерге қатысты. Құпиялылық саясаты.
         Бұл ақпарат техникалық анықтау және шешу үшін пайдаланылады
         алаяқтықпен күресу мәселелері. Ақпаратқа бағынады
         жағдайларды қоспағанда, қауіпсіз сақтау және таратпау
         Құпиялық саясатымен және заңмен қамтамасыз етілген
         Қазақстан Республикасы.` }}

        
      </p>
      <p>
          {{ $cookie.get('lang') === 'Ru' ? ` Общество и Администрация сайта вправе предоставлять информацию
        Пользователей своим аффилированным лицам (в том числе осуществляющих
        свою деятельность за пределами территории Республики Казахстан), а также
        иным компаниям, связанным с Обществом в целях, указанных выше. При этом
        аффилированные лица, а также связанные с ним компании обязаны
        придерживаться положений настоящей Политики. Обществом не
        предоставляется информация Пользователей компаниям и частным лицам, не
        связанным с Обществом, за исключением перечисленных ниже случаев: ·
        Пользователь дал на это свое согласие; · По основаниям и в порядке,
        установленным законодательством Республики Казахстан, в том числе с
        целью: – выполнения судебных решений или исполнения законных требований
        государственных органов; – устранения технических сбоев или проблем
        безопасности; – защиты прав, собственности или безопасности Общества,
        пользователей Сервисов Общества в рамках, допускаемых действующим
        законодательством.` : `Компания мен Сайт Әкімшілігі ақпарат беруге құқылы
         Пайдаланушылар өздерінің аффилиирленген тұлғаларына (соның ішінде
         оның Қазақстан Республикасының аумағынан тысқарыдағы қызметі), сондай-ақ
         жоғарыда көрсетілген мақсаттар үшін Қоғаммен байланысты басқа компаниялар. Бола тұра
         еншілес компаниялар, сондай-ақ онымен байланысты компаниялар талап етіледі
         осы Саясаттың ережелерін ұстануға. Қоғам жоқ
         Пайдаланушылар туралы ақпарат компанияларға және жеке тұлғаларға беріледі
         төменде көрсетілген жағдайларды қоспағанда, Қоғаммен байланысты:
         Пайдаланушы бұған келісімін берді; Негіздері мен тәртібі бойынша,
         Қазақстан Республикасының заңнамасында белгіленген, оның ішінде
         Мақсаты: – сот шешімдерін орындау немесе заң талаптарын орындау
         мемлекеттік органдар; – техникалық ақауларды немесе ақауларды жою
         қауіпсіздік; – Қоғамның құқықтарын, мүлкін немесе қауіпсіздігін қорғау;
         қолданыстағы рұқсат етілген шектерде Компанияның Қызметтерін пайдаланушылар
         заңнама.` }}

       
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
          {{ $cookie.get('lang') === 'Ru' ? 'Порядок действия политики конфиденциальности и внесения в нее изменений' : 'Құпиялық саясатының тәртібі және оған өзгерістер енгізу' }}

        
      </h2>
      <p>
          {{ $cookie.get('lang') === 'Ru' ? `Политика вступает в силу на неограниченный срок с момента ее утверждения
        Обществом. Действующая Политика признается недействительной после ее
        замены новой Политикой. Замена Политики осуществляется путем публикации
        ее новой редакции на Сайте. Действующая редакция Политики, являющаяся
        публичным документом, доступна любому пользователю сети Интернет при
        переходе по ссылке` : `Саясат бекітілген сәттен бастап шексіз мерзімге күшіне енеді
         Қоғам. Ағымдағы Саясат одан кейін жарамсыз болып табылады
         жаңа Саясатпен ауыстырылды. Саясатты ауыстыру жариялау арқылы жүзеге асырылады
         оның сайттағы жаңа басылымы. Саясаттың ағымдағы нұсқасы, ол
         кез келген интернет пайдаланушыға қолжетімді жалпыға қолжетімді құжат
         сілтемеге өтіңіз` }}

        
        <a href="#" class="payment-mail"> https://tahit.kz/payment.</a>
          {{ $cookie.get('lang') === 'Ru' ? `Администрация Сайта вправе вносить изменения в Политику, которые не
        противоречат законодательству Республики Казахстан. Размещение на Сайте
        новой редакции Политики после ее утверждения является уведомлением
        пользователей Сайта о внесенных в Политику изменениях.` : `Сайт әкімшілігі Саясатқа енгізілмеген өзгерістер енгізуге құқылы
         Қазақстан Республикасының заңнамасына қайшы келеді. Сайтта орналастыру
         Саясаттың жаңа нұсқасы бекітілгеннен кейін хабарлама болып табылады
         Сайт пайдаланушылары Саясатқа енгізілген өзгерістер туралы.` }}

        
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
          {{ $cookie.get('lang') === 'Ru' ? 'Условия и цели обработки конфиденциальных данных' : 'Құпия мәліметтерді өңдеудің шарттары мен мақсаттары' }}

        
      </h2>
      <p>
          {{ $cookie.get('lang') === 'Ru' ? `Администрация Сайта осуществляет обработку конфиденциальных данных
        Пользователя в следующих целях: - рассмотрение заявки Пользователя на
        подключение юридическому лицу/индивидуальному предпринимателю услуг,
        предоставляемых АО «ForteBank», а также взаимодействие в целях сбора
        необходимых документов и сведений для заключения соответствующего
        договора с АО «ForteBank» и (или) его партнерами, консультирование о
        предоставляемых услугах, информирование о действующих и новых продуктах
        АО «ForteBank» и его партнеров. - осуществление технического управления
        сайтом, а также для проведения анализа и улучшения его работы.` :`Сайт әкімшілігі құпия деректерді өңдейді
         Пайдаланушы келесі мақсаттарда: - Пайдаланушының өтінішін қарау
         заңды тұлғаға/жеке кәсіпкерге қызмет көрсетуге қосылу,
         «ForteBank» АҚ ұсынған, сондай-ақ жинау мақсатында өзара әрекеттесу
         тиісті қорытынды жасау үшін қажетті құжаттар мен мәліметтер
         «ForteBank» АҚ және (немесе) оның серіктестерімен келісімдер, кеңес беру
         ағымдағы және жаңа өнімдер туралы ақпарат беретін қызметтер
         «ForteBank» АҚ және оның серіктестері. - техникалық басқаруды жүзеге асыру
         сайт, сонымен қатар оның жұмысын талдау және жетілдіру.`}}

        
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
          {{ $cookie.get('lang') === 'Ru' ? 'Способы и сроки обработки конфиденциальных данных' : 'Құпия мәліметтерді өңдеу әдістері мен мерзімдері' }}

        
      </h2>
      <p>
          {{ $cookie.get('lang') === 'Ru' ? `Обработка данных Пользователя осуществляется без ограничения срока любым
        законным способом, в том числе в информационных системах персональных
        данных с использованием средств автоматизации или без использования
        таких средств. Пользователь дает согласие на обработку конфиденциальных
        данных без ограничения срока. Данные Пользователя могут быть переданы
        уполномоченным органам государственной власти Республики Казахстан
        только по основаниям и в порядке, установленными законодательством
        Республики Казахстан. Администрация сайта обязана принимать необходимые
        организационные и технические меры для обеспечения конфиденциальности,
        целостности, сохранности и доступности конфиденциальных данных
        Пользователей, а также соблюдать иные требования к обработке данных,
        установленные законодательством Республики Казахстана. При утрате или
        разглашении конфиденциальных данных Администрация сайта информирует
        Пользователя об утрате или разглашении его данных. .Администрация сайта
        совместно с Пользователем принимает все необходимые меры по
        предотвращению убытков или иных отрицательных последствий, вызванных
        утратой или разглашением конфиденциальных данных Пользователя.` : `Пайдаланушының деректерін өңдеу кез келген уақытта шектеусіз жүзеге асырылады
         заңды түрде, оның ішінде жеке тұлғаның ақпараттық жүйелерінде
         автоматтандыруы бар немесе онсыз деректер
         осындай қорлар. Пайдаланушы құпия ақпаратты өңдеуге келісім береді
         деректер шексіз. Пайдаланушы деректері тасымалдануы мүмкін
         Қазақстан Республикасының уәкілетті мемлекеттік органдары
         заңда белгіленген негіздер мен тәртіппен ғана жүзеге асырылады
         Қазақстан Республикасы. Сайт әкімшілігі қажет нәрсені алуға міндетті
         құпиялылықты қамтамасыз ету бойынша ұйымдастырушылық-техникалық шаралар,
         құпия деректердің тұтастығы, қауіпсіздігі және қолжетімділігі
         Пайдаланушылар, сондай-ақ басқа деректерді өңдеу талаптарын орындау,
         Қазақстан Республикасының заңнамасымен белгіленеді. Жоғалған жағдайда немесе
         құпия деректерді жариялау туралы сайт әкімшілігі хабарлайды
         Пайдаланушы өз деректерінің жоғалуы немесе ашылуы туралы. .Сайт әкімшілігі
         Пайдаланушымен бірлесіп барлық қажетті шараларды қабылдайды
         туындаған шығындардың немесе басқа да жағымсыз салдардың алдын алу
         Пайдаланушының құпия деректерінің жоғалуы немесе ашылуы.` }}

      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
          {{ $cookie.get('lang') === 'Ru' ? 'Обязательства сторон' : 'Тараптардың міндеттемелері' }}
        
        </h2>
      <p>
          {{ $cookie.get('lang') === 'Ru' ? 'Пользователь обязан:' : 'Пайдаланушы міндетті:' }}
         <br />
          {{ $cookie.get('lang') === 'Ru' ? `Предоставить актуальные данные, запрашиваемые Сайтом в момент его
        использования. Обновить, дополнить предоставленные данные в случае
        изменения данной информации. Предварительно получить все необходимые
        согласия от третьих лиц в случае предоставления их данных Администрации
        сайта.` : `Сайт жасау кезінде сұраған өзекті деректерді қамтамасыз етіңіз
         пайдалану. Жағдайда берілген деректерді жаңарту, толықтыру
         осы ақпаратқа өзгерістер енгізу. Қажетті нәрсенің бәрін алдын ала алыңыз
         олардың деректерін Әкімшілікке берген жағдайда үшінші тұлғалардың келісімі
         сайт.` }}

        
      </p>
      <p>
          {{ $cookie.get('lang') === 'Ru' ? 'Администрация сайта обязана:' : 'Сайт әкімшілігі міндетті:' }}
         <br />
          {{ $cookie.get('lang') === 'Ru' ? `Использовать полученные конфиденциальные данные исключительно для целей,
        указанных в Разделе 4 Политики конфиденциальности. Обеспечить хранение
        конфиденциальной информации в тайне, не разглашать ее без
        предварительного письменного разрешения Пользователя, а также не
        осуществлять продажу, обмен, опубликование, либо разглашение иными
        возможными способами конфиденциальных данных, за исключением случаев,
        предусмотренных Политикой конфиденциальности, заключенными договорами с
        клиентами, законодательством Республики Казахстан. Принимать меры
        предосторожности для защиты конфиденциальности данных Пользователя
        согласно порядку, обычно используемого для защиты такого рода информации
        в существующем деловом обороте, и в соответствии с требованиями
        законодательства Республики Казахстан.` : `Алынған құпия деректерді тек мақсаттар үшін пайдаланыңыз,
         Құпиялық саясатының 4-бөлімінде көрсетілген. Сақтауды қамтамасыз ету
         құпия ақпаратты жасырын, онсыз жария етпеңіз
         Пайдаланушының алдын ала жазбаша рұқсаты және
         сатуға, айырбастауға, жариялауға немесе басқа жолмен ашуға
         жағдайларды қоспағанда, құпия деректердің ықтимал құралдары
         құпиялылық саясатында көзделген, келісім-шарттар
         клиенттер, Қазақстан Республикасының заңнамасы. шара қолдану
         Пайдаланушы деректерінің құпиялылығын қорғау бойынша сақтық шаралары
         ақпараттың осы түрін қорғау үшін әдетте қолданылатын процедураға сәйкес
         қалыптасқан шаруашылық айналымында және талаптарға сәйкес
         Қазақстан Республикасының заңнамасы.` }}

        
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
          {{ $cookie.get('lang') === 'Ru' ? 'Ответственность сторон' : 'Тараптардың жауапкершілігі' }}
        
        </h2>
      <p>
          {{ $cookie.get('lang') === 'Ru' ? `Администрация сайта, не исполнившая свои обязательства, несёт
        ответственность за убытки, понесённые Пользователем в связи с
        неправомерным использованием конфиденциальных данных, в соответствии с
        законодательством Республики Казахстан, за исключением случаев,
        предусмотренных п.7.2. настоящей Политики Конфиденциальности. В случае
        утраты или разглашения Конфиденциальной информации Администрация сайта
        не несёт ответственность, если данная конфиденциальная информация: Стала
        публичным достоянием до её утраты или разглашения. Была получена от
        третьей стороны до момента её получения Администрацией сайта. Была
        разглашена с согласия Пользователя. Пользователь возмещает АО
        «ForteBank» в полном объеме ущерб в случае предъявления третьими лицами,
        в том числе государственными органами, к АО «ForteBank» претензий,
        исков, касающихся Обработки Персональных данных, переданных
        Пользователем Администрации сайта без согласия субъекта персональных
        данных, Обработки недостоверных/искаженных/неактуальных Персональных
        данных по вине Пользователя.` : `Міндеттемесін орындамаған сайт әкімшілігі жауап береді
         байланысты Пайдаланушы шеккен шығындар үшін жауапкершілік
         сәйкес құпия деректерді теріс пайдалану
         жағдайларды қоспағанда, Қазақстан Республикасының заңнамасы,
         7.2 тармағында көзделген. осы Құпиялық саясатының. Егер
         Құпия ақпарат сайтының әкімшілігінің жоғалуы немесе ашылуы
         бұл құпия ақпаратқа жауапты емес: Болды
         ол жоғалғанға немесе ашылғанға дейін қоғамдық домен. -дан алынды
         Сайт әкімшілігі оны алғанға дейін үшінші тарап. болды
         Пайдаланушының келісімімен ашылады. Пайдаланушы AO қайтарады
         Үшінші тұлғалар ұсынған жағдайда «ForteBank» толық шығынды өтейді,
         оның ішінде мемлекеттік органдар, «ForteBank» АҚ-қа қарсы талаптар,
         берілген жеке деректерді өңдеуге қатысты шағымдар
         Сайт әкімшілігінің пайдаланушысы субъектінің келісімінсіз жеке
         деректерді өңдеу, дәл емес/бұрмаланған/ескірген Жеке
         Пайдаланушының кінәсінен деректер.` }}

        
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
          {{ $cookie.get('lang') === 'Ru' ? 'Разрешение споров' : 'Дауды шешу' }}
        
        </h2>
      <p>
          {{ $cookie.get('lang') === 'Ru' ? `До обращения в суд с иском по спорам, возникающим из отношений между
        Пользователем сайта` : `арасындағы қатынастардан туындайтын даулар бойынша талап арызбен сотқа жүгінгенге дейін
         Сайт пайдаланушысы` }}

        
        <a href="#" class="payment-mail"> https://tahit.kz </a>
        {{ $cookie.get('lang') === 'Ru' ? `и Администрацией
        сайта, обязательным является предъявление претензии (письменного
        предложения о добровольном урегулировании спора). Получатель претензии в
        течение 30 (Тридцати) календарных дней со дня получения претензии,
        письменно уведомляет заявителя претензии о результатах рассмотрения
        претензии. При не достижении соглашения спор будет передан на
        рассмотрение в судебный орган в соответствии с действующим
        законодательством Республики Казахстан. К настоящей Политике
        конфиденциальности и отношениям между Пользователем и Администрацией
        сайта применяется действующее законодательство Республики Казахстан.` : `және Әкімшілік
         сайтында талап қою міндетті (жазбаша
         дауды ерікті түрде реттеу туралы ұсыныстар). Шағымды алушы
         талап арыз келіп түскен күннен бастап 30 (отыз) күнтізбелік күн ішінде,
         қарау нәтижелері туралы талап қоюшыға жазбаша хабарлайды
         талаптар. Егер келісімге қол жеткізілмесе, дау қаралады
         қолданыстағы заңнамаға сәйкес сот органында қарау
         Қазақстан Республикасының заңнамасы. Осы Саясатқа
         құпиялылық және Пайдаланушы мен Әкімшілік арасындағы қарым-қатынас
         сайт Қазақстан Республикасының қолданыстағы заңнамасына бағынады.` }}
         
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        
         {{ $cookie.get('lang') === 'Ru' ? `Дополнительные условия` : `Қосымша шарттар` }}
         </h2>
      <p>
         {{ $cookie.get('lang') === 'Ru' ? `Обработка персональных данных осуществляется с момента заключения
        договоров АО «ForteBank» с клиентами и регулируется их условиями.
        Пользователь/субъект персональных данных в любое время может отозвать
        согласие на Обработку Персональных данных посредством направления
        письменного заявления АО «ForteBank» по адресу: г. Астана, ул. Достык,
        8/1. В указанном случае Администрация сайта может продолжить
        обрабатывать Персональные данные без согласия Пользователя/субъекта
        Персональных данных в случаях, установленных законодательством
        Республики Казахстан. Пользователь/субъект персональных данных имеет
        право реализовать свои права, предусмотренные Главой 4 Закона Республики
        Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их
        защите», в том числе получить информацию, касающуюся Обработки
        персональных данных, потребовать уточнения или блокирования, или
        уничтожения Персональных данных в случаях, предусмотренных законом,
        принимать меры по защите своих прав. Взаимодействие осуществляется в
        письменной форме по адресу, указанному в п. 9.2. Политики
        конфиденциальности. К настоящей Политике конфиденциальности и отношениям
        между Пользователем и Администрацией сайта, возникающим в связи с
        применением Политики конфиденциальности, подлежит применению право
        Республики Казахстан. Оператор вправе вносить изменения в настоящую
        Политику конфиденциальности без согласия Пользователя. Новая Политика
        конфиденциальности вступает в силу с момента ее размещения на сайте` : `Дербес деректерді өңдеу қорытынды жасалған сәттен бастап жүзеге асырылады
         «ForteBank» АҚ-ның клиенттермен келісім-шарттары және олардың шарттарымен реттеледі.
         Пайдаланушы/жеке деректер субъектісі кез келген уақытта күшін жоя алады
         жіберу арқылы жеке деректерді өңдеуге келісім беру
         Астана қ., көш., мекенжайы бойынша «ForteBank» АҚ жазбаша өтініші. Достық,
         8/1. Бұл жағдайда Сайт әкімшілігі жалғастыра алады
         Пайдаланушының/субъектінің келісімінсіз жеке деректерді өңдеу
         Заңда белгіленген жағдайларда дербес деректер
         Қазақстан Республикасы. Жеке деректердің пайдаланушысы/субъектісі бар
         Республика Заңының 4-тарауында көзделген өз құқықтарын жүзеге асыру құқығы
         Қазақстан Республикасының 2013 жылғы 21 мамырдағы № 94-V «Дербес деректер және олардың
         қорғау», соның ішінде өңдеуге қатысты ақпаратты алу
         жеке деректер, нақтылауды немесе блоктауды сұрау немесе
         Заңда көзделген жағдайларда жеке деректерді жою,
         құқықтарын қорғау үшін шаралар қабылдау. Өзара әрекеттесу орын алады
         9.2-тармағында көрсетілген мекенжайға жазбаша түрде. Саясаткерлер
         құпиялылық. Осы Құпиялық саясатына және қатынастарға
         байланысты туындайтын Пайдаланушы мен Сайт Әкімшілігі арасында
         Құпиялылық саясатын қолдану заңға бағынады
         Қазақстан Республикасы. Оператор бұған өзгерістер енгізуге құқылы
         Құпиялық саясаты Пайдаланушының келісімінсіз. Жаңа саясат
         құпиялылық саясаты сайтта жарияланған сәттен бастап күшіне енеді` }}
        <a href="#" class="payment-mail"> https://tahit.kz </a>
         {{ $cookie.get('lang') === 'Ru' ? `если иное не
        предусмотрено новой редакцией Политики конфиденциальности. Пользователь
        обязуется самостоятельно следить за изменениями Политики
        конфиденциальности путем ознакомления с актуальной редакцией. Все
        предложения или вопросы по настоящей Политике конфиденциальности следует
        сообщать посредством направления обращения с использованием Сайта.
        Политика конфиденциальности размещена на странице по адресу` : `егер басқаша болмаса
         Құпиялық саясатының жаңа нұсқасында қарастырылған. Пайдаланушы
         Саясатқа енгізілген өзгерістерді дербес бақылауға міндеттенеді
         ағымдағы нұсқаны қарау арқылы құпиялылық. Барлық
         осы Құпиялық саясаты туралы ұсыныстар немесе сұрақтар болуы керек
         Сайтты пайдаланып апелляцияны жіберу арқылы хабарласыңыз.
         Құпиялық саясаты мына бетте жарияланған` }}
        
         <a
          href="#"
          class="payment-mail"
        >
          https://tahit.kz/payment.</a
        >
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.payment {
  padding-top: 30px;
  padding-bottom: 30px;

  &-box {
    padding: 50px 0;
    border-bottom: 1px solid #a5aab5;
  }
  &__h2 {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    color: #1d393c;
    text-align: start;
  }
  &-flex {
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &-mail {
    color: #174baf;
  }
  &-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-list {
    list-style-type: disc;
    text-align: left;
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: 130%;
    color: #333333;
    margin: 20px 0;
    text-align: start;
    &__number {
      list-style-type: decimal;
      text-align: left;
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: 130%;
      color: #333333;
      margin: 20px 0;
      text-align: start;
    }
  }
}

p {
  text-align: left;
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: 130%;
  color: #333333;
  margin: 20px 0;
  text-align: start;
}

br {
  display: block;
  margin: 5px 0;
}

li {
  margin: 5px 0;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.mt-50 {
  margin-top: 50px;
}

.my-5 {
  margin: 5px 0;
}
</style>
